import request from "../utils/request";

// 网站弹幕留言列表
export function barrageList() {
    return request({
        url: '/system/api/barrage/list',
        method: 'GET'
    })
}

// 网站弹幕留言
export function addBarrage(data) {
    return request({
        url: '/system/api/barrage/addBarrage',
        method: 'post',
        data
    })
}
